import React, { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { HelmetProvider, FilledContext } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PageWrap, Main, Notification } from './components';
import { NotificationContextProvider } from './lib/context/NotificationContext';
import { SearchAddressContextProvider } from './lib/context/SearchAddressContext';
import { AutocompleteContextProvider } from './lib/context/AutocompleteContext';
// import { CityContextProvider } from './lib/context/CityContext';
import { withSimulationContext } from './lib/context/SimulationContext';

const CssBaseline = Loadable({
  loader: () => import('./components/CssBaseline/CssBaseline'),
  loading() {
    return null;
  },
});

const Header = Loadable({
  loader: () => import('./components/Header/Header'),
  loading() {
    return null;
  },
});
const Footer = Loadable({
  loader: () => import('./components/Footer/Footer'),
  loading() {
    return null;
  },
});

const Home = Loadable({
  loader: () => import('./pages/home/Home'),
  loading() {
    return null;
  },
});

const About = Loadable({
  loader: () => import('./pages/about/About'),
  loading() {
    return null;
  },
});

const Company = Loadable({
  loader: () => import('./pages/company/Company'),
  loading() {
    return null;
  },
});

const Contact = Loadable({
  loader: () => import('./pages/contact/Contact'),
  loading() {
    return null;
  },
});

const Faq = Loadable({
  loader: () => import('./pages/faq/Faq'),
  loading() {
    return null;
  },
});

const Privacy = Loadable({
  loader: () => import('./pages/privacy/Privacy'),
  loading() {
    return null;
  },
});

const Term = Loadable({
  loader: () => import('./pages/term/Term'),
  loading() {
    return null;
  },
});

const TermBtoB = Loadable({
  loader: () => import('./pages/term/btob/TermBtoB'),
  loading() {
    return null;
  },
});

const SharpSim = Loadable({
  loader: () => import('./pages/sharp-simulation/SharpSim'),
  loading() {
    return null;
  },
});

const Maintenance = Loadable({
  loader: () => import('./pages/maintenance/Maintenance'),
  loading() {
    return null;
  },
});

const NotFound = Loadable({
  loader: () => import('./pages/not-found/NotFound'),
  loading() {
    return null;
  },
});

const Search = Loadable({
  loader: () => import('./pages/search/Search'),
  loading() {
    return null;
  },
});

const Odawara = Loadable({
  loader: () => import('./pages/cities/Odawara'),
  loading() {
    return null;
  },
});

// 2024-09: 福岡市向けSuncleTOP開発
const Fukuoka = Loadable({
  loader: () => import('./pages/cities/fukuoka/Fukuoka'),
  loading() {
    return null;
  },
});

const THTSim = Loadable({
  loader: () => import('./pages/tht-simulation/ThtSim'),
  loading() {
    return null;
  },
});

export const helmetContext = {} as FilledContext;

const App: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [preloaded, setPreloaded] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (isOpen && !preloaded) {
      Contact.preload();
      // HowItWorks.preload();
      About.preload();
      Faq.preload();
      setPreloaded(true);
    }
  }, [isOpen, preloaded]);

  return (
    <>
      <CssBaseline />
      <PageWrap>
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <Main>
          <HelmetProvider context={helmetContext}>
            <NotificationContextProvider>
              <SearchAddressContextProvider>
                <AutocompleteContextProvider>
                  {/* <CityContextProvider> */}
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/company" element={<Company />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/contact/complete" element={<Contact />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/term" element={<Term />} />
                    <Route path="/sim/term" element={<TermBtoB />} />
                    {/*
                      2023-10-26: シャープのシミュレーションの一時停止対応
                      メンテナンス中に変更する場合、SharpSimをコメントアウトし、
                      Maintenanceのコメントアウトを解除する。
                      フッターの非表示はFooter.tsxのsetShowFooterにfalseを設定する。
                    */}
                    <Route path="/sim/sharp" element={<SharpSim />} />
                    {/* <Route path="/sim/tepcohometech" element={<THTSim />} /> */}
                    <Route path="/sim/tepcohometech" element={<Maintenance />} />
                    <Route path="/odawara" element={<Odawara />} />
                    {/* 2024-09: 福岡市向けSuncleTOP開発 */}
                    {/* <Route path="/fukuoka" element={<Fukuoka />} /> */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  {/* </CityContextProvider> */}
                  <Notification />
                </AutocompleteContextProvider>
              </SearchAddressContextProvider>
            </NotificationContextProvider>
          </HelmetProvider>
        </Main>
        <Footer />
      </PageWrap>
    </>
  );
};

export default withSimulationContext(App);
